.daterangepicker .calendar-table {
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #fff;
  background: #0f1322;
  color: #000;
}

.daterangepicker {
  background: #0f1322;
}
.daterangepicker .calendar-table {
  border: 1px solid #0f1322;
}

.prev {
  background-color: #59668b;
}
.next {
  background-color: #59668b;
}
.daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
  background-color: transparent;
}
.image {
  position: relative;
  /* transform-origin: 50% 50%;
  transition: transform 1s linear; */
}
.image:hover {
  transform: scale(11.8, 7.5);
  padding-right: 12px;
  padding-bottom: 4px;
  cursor: pointer;
  z-index: 999;
}
.cancelBtn:hover {
  color: #FFF;
  border: 0.5px solid #acabab;
}
.iiq-anomalies-report-sec {
  margin: 20px 30px;
}

.iiq-anomalies-reports-header {
  display: flex;
  background-color: #222b45;
  flex-direction: column;
  border-radius: 4px;
}

.iiq-anomalies-reports-header .iiq-header {
  color: #fff;
  font-size: 35px;
  width: 30%;
  margin: 0;
}

.iiq-anomalies-reports-header .date-pick{
  align-items: center;
  justify-content: center;
}

.iiq-anomalies-reports-header div.flex-row {
  border-bottom: 1px solid #0f1322;
  padding: 0 10px;
}

.date-pick-comp {
  background: #0f1322;
  color: #fff;
  border-radius: 5px;
  border: 1px solid #0f1322;
}

.MuiTableRow-root:nth-child(even){
  background-color: #111728;
}

.MuiTableSortLabel-root:hover{
  color: #fff !important;
}

.no-data-info {
  color: #fff;
  font-size: 16px;
}
.MuiTableCell-root {
  border-bottom: 0 !important;
}
.MuiPaper-root {
  color: #fff !important;
  background-color: #222b45 !important;
}

.MTablePaginationInner-root-32 {
  color: #fff !important;
}
.MuiTableHead-root th {
  background-color: #222b45 !important;
  border-bottom: 1px solid #111728 !important;
}

.notification-report-table td{
  vertical-align: middle;
}
.notification-report-table td input,
.notification-report-table td select{
  background: transparent;
  border: none;
  width: 100%;
  border: 1px solid #454545;
  padding: 10px;
  border-radius: 4px;
}

.iiq-filter-tr td{
  --bs-table-accent-bg: #222b45 !important;
}

select.iiq-select-filter option,
.notification-report-table td select option {
  background: #222b45;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.notification-report-table tbody tr:nth-child(even) td{
  background: transparent;
}

.table-striped>tbody>tr:nth-of-type(odd) {
  --bs-table-accent-bg: #222b45;
  color: var(--bs-table-striped-color);
}

.table>:not(:last-child)>:last-child>* {
  border-bottom-color: #111728;
}

.notification-report-table tbody tr:first-child{
  border-bottom-color: #fff;
}
.table>:not(caption)>*>* {
  background: #222b45;
}
.notification-report-table .table>:not(caption)>*>* {
  background: #222b45;
  box-shadow: unset;
}

.iiq-anomalies-report-sec .filter-btn button {
  background: #111728;
  border: 0;
  padding: 5px;
  color: #fff;
  font-weight: bold;
  width: 100px;
  border: 1px solid #b5b5b5;
  border-radius: 2px;
}
.iiq-anomalies-report-sec .filter-btn button .MuiSvgIcon-root{
  margin-right: 5px;
}
.count-set {
  margin: 2px 20px;
  color: #aeaeae;
  font-size: 16px;
}
.count-set .MuiFormControl-root{
  align-items: end;
  margin-top: 0 !important;
}
.zoom-image {
  position: absolute;
  width: 500px;
  top: 40%;
  right: 200px;
}

.pagination-container {
  align-items: center;
  margin-bottom: 5px;
}

.pagination-container button{
  border: 1px solid #fff;
  color: #fff;
}

.pagination-container .MuiPaginationItem-root {
  color: #fff;
}

.pagination-container .MuiPaginationItem-page.Mui-selected {
  background-color: #16479e;
}
.pagination-container .MuiPaginationItem-page.Mui-selected:hover {
  background-color: #3c84ff;
}

.status-filter .status-accepted .MuiSvgIcon-root,
.status-filter .status-accepted .PrivateRadioButtonIcon-checked-32 .MuiSvgIcon-root {
  color: #18d818;
}
.status-filter .status-pending .MuiSvgIcon-root,
.status-filter .status-pending .PrivateRadioButtonIcon-checked-32 .MuiSvgIcon-root {
  color: #fdaa0f;
}
.status-filter .status-rejected .MuiSvgIcon-root,
.status-filter .status-rejected .PrivateRadioButtonIcon-checked-32 .MuiSvgIcon-root {
  color: #ff1414;
}

.text-ellipsis--2 {
  text-overflow:ellipsis;
  overflow:hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.comments.tooltip-custom .tooltiptext-custom {
  width: 400px;
}

.iiq-select-filter.zone-search{
  background: #0f1322;
  padding: 11px;
  color: #fff;
  border-radius: 5px;
  width: 200px;
  margin: 0 10px;
  border: 0;
}

.search-submit {
  background-color: #1890ff !important;
  color: #fff !important;
  padding: 6px 12px !important;
  font-weight: normal !important;
  text-transform: initial !important;
}

.search-submit:hover {
  background-color: #16479e !important;
}
.show-info {
  color: #ffa500;
}