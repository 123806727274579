.nav-dashboard {
  display: flex;
  width: 100%;
  height: 100%;
}

.error-modal .ant-modal-content {
  background-color: #131826 !important;
  border: 1px solid #777474 !important;
  border-radius: 5px !important;
}
.error-modal .ant-modal-confirm-body .ant-modal-confirm-content,
.error-modal .ant-modal-confirm-body .ant-modal-confirm-title{
  color: #fff !important;
}