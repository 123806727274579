.title_text {
  width: 100%;
  font-size: 16px;
  margin-left: 20px;
  margin-top: 20px;
}

.platform-split {
  display: block;
}

.platform-split.left {
  width: 40%;
  background-color: #111728;
  color: white;
}

.platform-split.right {
  width: 60%;
  background-color: #111728;
  border-left: 2px solid #040508;
}
.settings-screen {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
}
.profile
{
  margin-left : 20px;
  font-size : 20px;
}

.img-settings{
  margin: 20px 50px 0px 50px;
  width : 80px;
}
.icon-settings{
  margin-right: 20px;
  margin-bottom:50px;
}

.btn
{
  background: #111728;
  color : #FFF;
  padding : 5px 15px;
  margin-right:15px;
  margin-top:20px;
}

password-settings{
  margin-left: 20px;
}