    
.form-control-sort-by .iiq-select-filter {    
    background: transparent;
    border: transparent;
    width: inherit;
    color: #fff;
    height: 30px;
}

.form-control-sort-by {
    margin: 0 10px !important;
}