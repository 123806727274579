.welcome-screen {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
}

.split {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.split.left {
  background-color: #111728;
  color: white;
}

.split.left h1 {
  margin-bottom: 10px;
  margin-top: 40px;
}

.split.left h2 {
  font-weight: 300;
  margin-top: 0;
  font-size: 25px;
}

.split.right {
  background-color: #0a0c13;
}

.split.right .info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  margin-top: -35%;
}

.split.right h2 {
  margin: 0;
  font-size: 25px;
  font-weight: normal;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #0a0c13 inset !important;
    -webkit-text-fill-color: #ffffff !important;
}
.LoginForm .password,
.LoginForm .username{
  border: 1px solid #474e61 !important;
  padding: 5px 12px;
  border-radius: 5px;
  margin: 10px 0;
  width: 300px;
}
.LoginForm .password .MuiInputBase-root,
.LoginForm .username .MuiInputBase-root {
  border-bottom: none !important
}

.LoginForm .MuiFormControl-marginNormal {
  margin: 0 !important;
}

.button {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
}

.button button:disabled {
  background-color: #4169e1;
}
.button button:enabled {
  background-color: #36f;
  color: white;
}

.button button {
  width: 100px;
}

.text-field input {
  color: white;
}

.MuiButton-text {
  padding: 0 !important;
}

.link.submit {
  display: block;
  text-decoration: none;
  background: #3f51b5;
  padding: 6px 11px;
  border-radius: 4px;
  color: white;
}
.mobile {
  visibility: hidden;
}
.mobile-login {
  margin-top: -50%;
}

.LoginForm {
  align-items: center;
  justify-content: center;
  display: inline-grid;
  padding-top: 70px;
}
.LoginForm .login-title{
  color: #fff;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .split {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mobile {
    visibility: inherit;
    margin-top: 30%;
  }
  .mobile-hidden {
    width: 0px;
  }
  .mobile-login {
    margin-top: 50px;
  }
}
