.card {
  /* width: 210px; */
  background-color: #222b45;
  margin: 0;
  /* margin: 0 30px 30px 0; */
  border: 1px solid rgba(255, 255, 255, 0.3);
  width: 15%;
  margin-bottom: 30px;
  position: relative;
  transition: transform .2s;
}

.card:hover {
  transform: scale(1.1);
}

.card:after {
  content: "";
  position: absolute;
  width: calc(100% + 2px);
  height: 3px;
  left: -1px;
  top: 100%;
}
.card.high:after {
  background: #ff0008;
}
.card.medium:after {
  background: #ffa500;
}
.card.low:after {
  background: #ffff00;
}
.card.wip:after {
  background: #29aaef;
}

.card a {
  display: block;
  color: white;
  text-decoration: none;
}

.card a:hover {
  opacity: 0.8;
}

.card img {
  height: 20px;
}

.card iframe {
  border: none;
  display: block;
  border-bottom: 1px solid #f3f3f342;
  pointer-events: none;
}

.card .info {
  padding: 7px 10px;
  color: white;
  font-size: 0.8rem;
}

.card .top {
  justify-content: space-between;
  margin-bottom: 10px;
}

.card .bottom {
  margin: 0;
  padding: 0;
  justify-content: space-between;
}

.card .bottom img {
  margin-right: 5px;
}

.card .options {
  cursor: pointer;
  padding: 5px;
}

.car .menuOptions {
  padding: 5px 10px;
  background: #0d111e;
  color: #fff;
}

.car .menuOptions:hover {
  color: #2dd1ac;
}

.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  margin: -5px -12px;
  padding: 10px 50px;
  font-size: 16px;
}

.ant-dropdown-menu-item:hover {
  color: #2dd1ac;
  background: #0d111e;
}

@media only screen and (max-width: 3000px) and (min-width: 2500px) {
  .card {
    width: 13%;
    margin-right: 1.5%;
  }
  .card:nth-child(7n) {
    margin-right: 0%;
  }
}

@media only screen and (max-width: 2500px) and (min-width: 2000px) {
  .card {
    width: 13%;
    margin-right: 1.5%;
  }
  .card:nth-child(7n) {
    margin-right: 0%;
  }
}

@media only screen and (max-width: 2000px) and (min-width: 1300px) {
  .card {
    width: 23%;
    margin-right: 2.5%;
  }
  .card:nth-child(4n) {
    margin-right: 0%;
  }
}

@media only screen and (max-width: 1300px) and (min-width: 1000px) {
  .card {
    width: 32%;
    margin-right: 2%;
  }

  .card:nth-child(3n) {
    margin-right: 0%;
  }
  .card .info {
    font-size: 0.7rem;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 600px) {
  .card {
    width: 48%;
    margin-right: 4%;
  }
  .card:nth-child(2n) {
    margin-right: 0;
  }
}

@media only screen and (max-width: 600px) {
  .card {
    width: 100%;
  }
}

.frame {
  width: 100%;
  height: 100%;
}

.frame img {
  width: 100%;
  height: 100%;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-right-align {
  display: flex;
}

.iiq-card-footer .flex-column:first-child {
  width: 30%;
}
.iiq-card-footer .flex-column:nth-child(2) {
  width: 70%;
}

.iiq-card-footer img {
  margin-right: 5px;
}
.m-L4 {
  margin-left: 4px;
}
.m-L8 {
  margin-left: 8px;
}
.flex-end {
  align-items: flex-end;
}