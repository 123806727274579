.header {
  height: 70px;
  background-color: #222b45;
  width: 100%;
  color: white;
  justify-content: space-between;
  border-bottom: 1px solid #f3f3f342;
  width: 100%;
  z-index: 8;
}

.header .left,
.header .right {
  height: 100%;
}

.header .menu {
  width: 65px;
  align-self: stretch;
  border: none;
  background-color: #222b45;
  cursor: pointer;
  border-right: 1px solid #151a30;
  text-align: right;
  padding-right: 18px;
  transition: all 0.2s ease;
}

.header .menu:hover {
  opacity: 0.8;
}

.header .menu .arrow {
  display: none;
  transform: rotate(90deg);
  width: 35px;
  height: 25px;
}

.header .menu img {
  width: 25px;
  pointer-events: none;
}

/* .header .logo {
  margin-left: 28px;
}

.header .logo:hover {
  opacity: 0.9;
} */

/* .header .logo img {
  width: 90px;
} */

.header .notification {
  background-color: #222b45;
  height: 100%;
  width: 80px;
  border-left: 1px solid #151a30;
  border-right: 1px solid #151a30;
  cursor: pointer;
}

.header .notification svg {
  width: 26px;
  fill: white;
}

.header .refresh-notification-btn svg {
  width: 30px;
  fill: white;
}

.header .refresh-notification-btn {
  margin: 20px;
}
.header .notification .icon {
  position: relative;
  margin-top: 6px;
}

.header .notification:hover .icon {
  opacity: 0.9;
}

.header .notification .icon .count {
  position: absolute;
  background: red;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  right: -16px;
  top: -16px;
  font-size: 12px;
}

.header .user {
  background-color: #222b45;
  height: 100%;
  padding: 0 15px;
  cursor: pointer;
  color: white;
  text-decoration: none;
}

.header .user .pic {
  width: 36px;
}

.header .user .arrow {
  width: 17px;
}

.header .user .user-name {
  font-size: 14px;
  margin: 0 13px 0 10px;
}

.header .user:hover .arrow,
.header .user:hover .pic,
.header .user:hover .user-name {
  opacity: 0.9;
}

/* ******* */
/* OPEN */
/* ******* */

/* .header.open .menu {
  width: 230px;
} */

.header.open .menu .lines {
  display: none;
}

.header.open .menu .arrow {
  display: inline-block;
}
.button-logout {
  background-color: #66d1ac;
  margin-top: 0px;
  padding: 5px 20px;
  border-radius: 5px;
}

.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: absolute;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #111728;
  text-align: center;
  min-width: 160px;
  right: 0;
  margin-top: 30px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content .user-name {
  color: #ffffff;
  padding: 12px;
  text-decoration: none;
  display: block;
}

.dropdown-content .log-out {
  color: #ffffff;
  padding: 12px;
  text-decoration: none;
  display: block;
}
/* Change color of dropdown links on hover */
.dropdown-content .user-name:hover {
  background-color: #111728;
}
.dropdown-content .log-out:hover {
  background-color: #111728;
}
/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}
.arrow {
  width: 100%;
  padding: 5px;
  align-self: start;
}

@media only screen and (max-width: 768px) {
  .header .menu {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .header .menu {
    display: none;
  }
}

.change-password {
  text-align: center;
  margin-top: 20px;
}
.ErrorMsg {
  margin: 0;
  color: red;
}

/* Tooltip container */
.tooltip-custom {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip-custom .tooltiptext-custom {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-custom:hover .tooltiptext-custom {
  visibility: visible;
}

.tooltip-custom .tooltiptext-custom {
  width: 120px;
  top: 100%;
  left: 50%;
  margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
  border: 1px solid #fff;
}

.tooltip-custom .tooltiptext-custom::after {
  content: " ";
  position: absolute;
  bottom: 100%;  /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
}