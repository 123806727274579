* {
  box-sizing: border-box;
}

*:focus,
*:active {
  outline: none;
  box-shadow: none;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  background-color: #151a30;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex1 {
  display: flex;
  align-items: left;
  justify-content: left;
  margin-left: 23px;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
