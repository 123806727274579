.bar-default-sms {
  justify-content: left;
  border-bottom: 1px solid #f3f3f342;
  padding: 0 20px;
  overflow-x: auto;
}

.bar-default-sms ul {
  margin: 0 20px 0 0;
  padding: 0;
  background: #0f1726;
  height: 48px;
  justify-content: left;
}

.bar-default-sms ul li {
  list-style: none;
  align-self: stretch;
}

.bar-default-sms ul li a {
  width: 180px;
  height: 42px;
  color: white;
  text-decoration: none;
  font-size: 16px;
  height: 100%;
  padding: 0 20px;
  /* background: #1b233d; */
  border-right: 1px solid #f3f3f342;
}

.bar-default-sms ul li a.active {
  background: #0d111e;
}

.bar-default-sms ul li a span {
  margin-left: 12px;
}

.bar-default-sms ul li a span:hover {
  opacity: 0.8;
}

.bar-default-sms ul li a span svg {
  fill: white;
  width: 20px;
}

.bar-default-sms .add-btn {
  padding: 0;
  border: none;
  margin: 0;
  cursor: pointer;
}

.bar-default-sms .add-btn:hover {
  opacity: 0.8;
}

.bar-default-sms .add-btn img {
  width: 30px;
  display: block;
}
