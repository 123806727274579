.terms {
  max-width: 70vw;
  max-height: 80vh;
}
.terms > p {
  text-align: justify;
}
.terms > ol > li {
  text-align: justify;
}
.terms > ol > li > ol > li {
  text-align: justify;
}
.terms > ul > li {
  text-align: justify;
}
