.Rectangle-6 {
  height: 49px;
  border-radius: 2px;
  border: solid 1px #000000;
  background-color: #111728;
  color: #ffffff;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.preview {
  color: white;
  padding: 30px;
  text-align: left;
}

.SMS-LRF-1 {
  font-size: 20px;
  margin: 20px 30px;
  color: #fff;
}

.table1 {
  margin-top: 20px;
  padding-left: 20px;
}
.tr {
  margin-top: 5px;
 
  width: 475px;
  display: flex;
  justify-content: space-around;
}
.name {
  width: 40%;
}
.colon {
  width: 5%;
}

.val {
  width: 55%;
}

.dropdown {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 40px;
}

.status {
  margin-top: 5px;
  width: 400px;
  display: flex;
  margin-left: 10px;
  justify-content: flex-start;
}

.iiq--notification-slider {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}


.wrapper{
  min-height: 400px;
  display: flex;
  flex-direction: column;
  margin: 0 36px;
}
.content {
  display: flex;
  flex: 1;
  color: #000; 
}
.columns{
  display: flex;
  flex:1;
}
.main{
  flex: 1;
  order: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar-first{
  order: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
}
.sidebar-second{ 
  order: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
}

.slider-content img{
  transform-origin: 50% 50%;
  transition: transform 1s linear;
}
.slider-content img:hover {
  transform: scale(1.5);
}

.iiq--notification-details {
  background-color: #222b45;
  max-width: 100% !important;
  flex-basis: 100% !important;
}

.val1 {
  margin: 20px;
}

.iiq-back-btn:hover {
  color: rgb(16, 129, 241);
}