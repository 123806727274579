div.iiq-analytics-container {
    margin: 15px;
    color: #FFFFFF;
}

div.iiq-analytics-container .iiq-analytics-header {
    background: #222b45;
}

div.iiq-analytics-container .iiq-analytics-header .title-row{
    border-bottom: 1px solid #111728;
}

div.iiq-analytics-container .iiq-analytics-header .title-row h1{
    color: #FFFFFF;
    margin: 10px;
}

div.iiq-analytics-container .iiq-analytics-header .date-picker-row{
    align-items: center;
    padding: 10px;
}

div.iiq-analytics-container .iiq-analytics-header .date-picker-row label{
    margin-right: 10px;
    font-size: 14px;
}