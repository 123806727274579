.heading {
  margin-left: 10px;
  font-size: 17px;
  font-weight: 500;
}

.tr1 {
margin-top: 20px;
}

.textBox {
  color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #111728;
  height: 28px;
  width: 179px;
}

.submit {
  color: white;
  background-color: blue;
  position: unset;
  display: flex;
  align-items: flex-end;
  margin-left: 40%;
  margin-top: 10%;
  cursor: pointer;
  text-decoration: none;
  border: none;
  font-size: 16px;
  padding: 4px 10px;
}

.form {
    margin-top: 20px;
}

.required {
  color: red;
}