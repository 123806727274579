  .title_text {
    width: 100%;
    align-items: flex-start;
    font-size: 20px;
    margin-left: 40px;
    margin-top: 20px;
  }

  .flex1{
      margin-left: 20px;
      margin-top: 20px;
  }
  .reports{
    overflow-x: hidden;
  }