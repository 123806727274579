.Rectangle-6 {
  height: 49px;
  border-radius: 2px;
  border: solid 1px #000000;
  background-color: #111728;
  color: #ffffff;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.Configure-Rules {
  width: 164px;
  height: 25px;
  font-family: HelveticaNeue;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
}

.Rectangle-12 {
  width: 420px;
  box-shadow: -5px 6px 8px 0 rgba(128, 152, 213, 0.08);
  border: solid 1px #000000;
  background-color: #111728;
  color: #ffffff;
}
.Rectangle-11 {
  margin: 10px;
}
.img1 {
  width: 395px;
  height: 197px;
}

.Close {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.tr1 {
  margin-top: 10px;
  width: 400px;
  display: flex;
  justify-content: flex-start;
}
.name {
  width: 40%;
}
.val {
  width: 40%;
}
hr.new2 {
  margin-top: 10px;
  border-top: 1px dotted white;
}
.radio-grp {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.MuiFormControl-root {
  color: #ffffff;
}
.MuiFormLabel-root {
  color: #ffffff;
}
.MuiInputBase-root {
  border: 1px solid #5f5d70;
  width: 300px !important;
}

.MuiOutlinedInput-input {
  padding: 5px !important;
}
.MuiSelect-root {
  color: white !important;
}
.MuiSvgIcon-root {
  color: #ffffff;
}
.MuiSelect-icon {
  color: #ffffff !important;
}
.MuiSelect-select:not([multiple]) option {
  background-color: black !important;
}
.MuiDrawer-paper{
  background-color: #111728 !important;
}
.Rectangle-19 {
  width: 200px;
  height: 31px;
  border-radius: 2px;
  border: solid 1px #5f5d70;
  background-color: #111728;
  color: #ffffff;
}

.hod {
  margin-top: 5px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

/* Radio button */
.container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: black;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #ffffff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  /* top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%; */
  background: white;
}
