.p-top {
  color: white;
}

.p-zone {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #5f5d70;
  margin-bottom: 20px;
}

.p-camera {
  margin-top: 20px;
}

.preview-main {
  color: white;
  padding-bottom: 30px;
  font-size: 18px;
}
