/* via https://projects.lukehaas.me/css-loaders/ */
.loader,
.loader:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: .7em solid rgba(255, 255, 255, 1.0);
  border-right: .7em solid rgb(255, 255, 255);
  border-bottom: .7em solid rgba(255, 255, 255, 1.0);
  border-left: .7em solid #66d1ac;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


.folding {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  transform: rotateZ(45deg);
 }
 
 .folding .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1);
 }
 
 .folding .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  transform-origin: 100% 100%;
 }
 
 .folding .sk-cube2 {
  transform: scale(1.1) rotateZ(90deg);
 }
 
 .folding .sk-cube3 {
  transform: scale(1.1) rotateZ(180deg);
 }
 
 .folding .sk-cube4 {
  transform: scale(1.1) rotateZ(270deg);
 }
 
 .folding .sk-cube2:before {
  animation-delay: 0.3s;
 }
 
 .folding .sk-cube3:before {
  animation-delay: 0.6s;
 }
 
 .folding .sk-cube4:before {
  animation-delay: 0.9s;
 }
 
 @keyframes sk-foldCubeAngle {
  0%,
  10% {
   transform: perspective(140px) rotateX(-180deg);
   opacity: 0;
  }
  25%,
  75% {
   transform: perspective(140px) rotateX(0deg);
   opacity: 1;
  }
  90%,
  100% {
   transform: perspective(140px) rotateY(180deg);
   opacity: 0;
  }
 }