.nav {
  width: 65px;
  background-color: #222b45;
  min-height: calc(100vh - 70px);
  border-right: 1px solid #f3f3f342;
  overflow: hidden;
  transition: all 0.2s ease;
}

.nav ul {
  margin: 0;
  padding: 0;
}

.nav ul li:hover {
  background-color: #0d111e;
  border-bottom: 1px solid #66d1ac;
}

.nav ul li a {
  color: white;
  text-decoration: none;
  width: 230px;
  display: flex;
}

.nav ul li a:hover {
  opacity: 0.8;
}

.nav ul li a img {
  width: 20px;
  display: block;
  height: 20px;
  margin-right: 5px;
  margin-top: 5px;
}

.nav ul li a .icon {
  width: 85px;
}

.nav ul li a .text {
  width: calc(100% - 85px);
  padding-left: 10px;
  font-size: 18px;
}

/* ******* */
/* OPEN */
/* ******* */

.nav.open {
  width: 225px;
}

.nav ul li {
  border-bottom: 1px solid #151a30;
  padding: 8px 0 8px 20px;
}

@media only screen and (max-width: 768px) {
  .nav ul li a {
    padding: 10px 0;
  }
}
