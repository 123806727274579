.Mask1 {
  margin: 20px;
  display: flex;
  color: #ffffff;
  align-items: center;
  display: flex;
  align-items: baseline;
}
.Mask1 div {
  margin-left: 20px;
}

.tile {
  display: flex;
  align-items: baseline;
}

.Mask {
  height: 46px;
  border: solid 2px #111a2a;
  background-color: #111728;
  color: #ffffff;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  align-items: baseline;
}

.Default {
  width: 150px;
  margin-top: 10px;
  height: 18px;
  font-family: HelveticaNeue;
  font-size: 18px;
  cursor: pointer;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  align-content: center;
  margin-left: 0px;
  margin-top: 0px;
  width: 97%;
}

td,
th {
  color: white;
  text-align: left;
  padding: 8px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 18px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 40px;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 2px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.hide {
  display: none;
}

.myRow:hover + .hide {
  display: block;
  background-color: #111728;
}
