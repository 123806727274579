.title_text {
    width: 100%;
    align-items: flex-start;
    font-size: 20px;
    margin-left: 10px;
    margin-top: 10px;
  }

  .flex1{
    margin-left: 20px;
    margin-top: 20px;
}