.iiq-analytics-body {
    margin: 15px 0;
}

.iiq-analytics-body .card-top {
    width: 100px;
    height: 100px;
    border: 2px solid #FFF;
    border-radius: 50%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.iiq-analytics-body .card-actions{
    border: 1px solid #fff;
    padding: 5px;
    width: 148px;
    border-radius: 8px;
    display: inline-block;
    margin: 8px;
}

.iiq-analytics-body .iiq-analytics-card-container {
    display: inline-block;
}

.iiq-analytics-card-container + .iiq-analytics-card-container {
    margin-left: 15px;
}

.iiq-analytics-body .iiq-card-footer {
    border-top: 1px solid #111728;
}