.form-control-date-pick {
    
}

.daterangepicker .calendar-table {
    border: 1px solid #fff;
    border-radius: 4px;
    background-color: #fff;
    background: #0f1322;
    color: #000;
}

.daterangepicker {
    background: #0f1322;
}
.daterangepicker .calendar-table {
    border: 1px solid #0f1322;
}
.daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
    background-color: transparent;
}
.iiq-form-control-date-picker {
    background: #0f1322;
    color: #fff;
    border-radius: 5px;
    border: 1px solid #0f1322;
    padding: 10px;
}