.preview {
    color: white;
    padding: 30px;
    text-align: left;
  }
  
  .SMS-LRF-1 {
    width: 422px;
    height: 26px;
    font-size: 26px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    margin-left: -20px;
    font-stretch: normal;
    margin-left:"1px";
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
  
  .table1 {
    margin-top: 20px;
  }
  .tr {
    margin-top: 10px;
    width: 450px;
    display: flex;
    justify-content: space-around;
  }
  .name {
    width: 40%;
  }
  .colon {
    width: 5%;
  }
  
  .val {
    width: 55%;
  }
  
  .card1 {
    height:170px;
    width: 370px;
    margin-top: 30px;
    margin-left: 10px;
  }
  
  .bottom {
    margin-top: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .number {
    font-family: HelveticaNeue;
    font-size: 42px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2dd1ac;
  }
  
  .text1 {
    font-family: HelveticaNeue;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
  
  .cards {
    display: flex;
  }
  
  .cameras {
    margin-top: 20px;
  }
  
  .txt-30 {
    font-size: 30px;
  }
  
  #dashboard1 {
    display: flex;
  }
  
  .card1 {
    width: 270px;
    /* height: 182px; */
    border-radius: 1px;
    background-color: #0f1726;
    color: white;
    margin: 10px;
  }

  @media only screen and (max-width: 3000px) and (min-width: 2500px) {
    .card1 {
      width: 13%;
      margin-right: 1.5%;
    }
    .card1:nth-child(7n) {
      margin-right: 0%;
    }
  }
  
  @media only screen and (max-width: 2500px) and (min-width: 2000px) {
    .card1 {
      width: 13%;
      margin-right: 1.5%;
    }
    .card1:nth-child(7n) {
      margin-right: 0%;
    }
  }
  
  @media only screen and (max-width: 2000px) and (min-width: 1300px) {
    .card1 {
      width: 18%;
      margin-right: 2.5%;
    }
    .card1:nth-child(5n) {
      margin-right: 0%;
    }
  }
  
  @media only screen and (max-width: 1300px) and (min-width: 1000px) {
    .card1 {
      width: 32%;
      margin-right: 2%;
    }
  
    .card1:nth-child(3n) {
      margin-right: 0%;
    }
  }
  
  @media only screen and (max-width: 1000px) and (min-width: 600px) {
    .card1 {
      width: 48%;
      margin-right: 4%;
    }
    .card1:nth-child(2n) {
      margin-right: 0;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .card1 {
      width: 100%;
    }
  }
  
  .iiq-header {
    font-size: 20px;
    margin: 10px 0;
  }

  .iiq-searchbar > div{
    border: 2px solid #222b45;
    width: 300px;
    border-radius: 50px;
    margin: 0;
    padding: 5px;
    margin: 5px 0;
  }
  .iiq--header-w-search-bar {
    display: flex;
    flex-direction: row;
  }
  .iiq--header-w-search-bar > div{
    width: 50%;
  }

  .iiq--header-w-search-bar > div:nth-child(2){
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .notification-table {
    font-size: 16px;
  }

  .table-striped>tbody>tr:nth-of-type(odd) {
    --bs-table-accent-bg: #0d111e !important;
    color: var(--bs-table-striped-color);
  }

  /* .notification-table th{
    background-color: #0D111E;
  }
  .notification-table td{
    background-color: #222b45;
  } */